<template>
  <section class="services">
    <div class="container" data-aos="fade-up">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12 text-center" style="margin: auto">
          <img
            src="@/assets/images/ReportingMetrics.jpg"
            alt="Img"
            style="width: 100%"
          />
        </div>
        <div
          class="col-lg-8 col-md-6 col-12"
          style="margin: auto; padding: 30px"
        >
          <div class="section-title">
            <h4>Our reporting metrics</h4>
            <p>
              We provide a detailed report with all possible proof of concepts
              along with the recommended security practices to be followed.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
.services {
  padding: 50px 40px;
}

@media (max-width: 998px) and (min-width: 0px) {
  .services {
    padding: 40px;
  }
}
input#exampleInputEmail1 {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
  border: none;
  padding: 10px;
  border-bottom: 2px solid;
}
.emailbutton {
  padding: 10px 32px;
  color: #0880e8;
  transition: 0.4s;
  border-radius: 50px;
  border: 2px solid #0880e8;
  background: #fff;
}
.emailbutton:hover {
  background: #0880e8;
  color: #fff;
}
</style>

<template>
  <CyberBanner />
  <HeaderService />
  <CyberHeader />
  <CyberSection />
  <Cybericons />
  <Contact style="background: #f6f7fd" />
  <Footer />
</template>

<script>
import CyberBanner from "@/components/services/cybersecurity/CyberBanner.vue";
import HeaderService from "@/components/services/HeaderService.vue";
import Footer from "@/components/Footer.vue";
import CyberSection from "@/components/services/cybersecurity/CyberSection.vue";
import CyberHeader from "@/components/services/cybersecurity/CyberHeader.vue";
import Cybericons from "@/components/services/cybersecurity/CyberIcons.vue";
import Contact from "@/components/landing/Contact.vue";

export default {
  components: {
    CyberBanner,
    HeaderService,
    Footer,
    CyberSection,
    CyberHeader,
    Cybericons,
    Contact,
  },
};
</script>

<template>
  <section id="features" class="services">
    <div class="container" data-aos="fade-up">
      <div class="section-title text-center p-4">
        <h1>Cyber Security - Features</h1>
      </div>

      <div class="row pt-4">
        <div
          class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch"
          v-for="(obj, key) in featureareaJSON"
          :key="key"
        >
          <div class="icon-box">
            <h4 class="softwarehead text-center">{{ obj.header }}</h4>
            <Image :fpath="obj.path" class="serviceimage" />
            <h6 class="softwaresubhead">{{ obj.subtitile }}</h6>
            <p>{{ obj.caption }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Image from "@/components/Image.vue";
export default {
  components: {
    Image,
  },
  data: () => ({
    featureareaJSON: [
      {
        path: "CloudPenetration.jpg",
        header: "Cloud Penetration Testing",
        caption:
          "Modern world applications are all hosted on cloud, ever wondered whether it is secure? What hardening checks are present. We perform multi cloud assessment - AWS, GCP, Azure.",
      },
      {
        path: "NetworkPenetration.jpg",
        header: "Network Penetration Testing",
        caption:
          "To effectively identify security vulnerabilities in applications & systems with a layered approach to evaluate the network’s security.",
      },
      {
        path: "WebApplication.jpg",
        header: "Web Application Pentesting ",
        caption:
          "Comprehensive DAST and SAST security assessment with a 360 approach covering end to end penetration testing inclusive of API’s to detect vulnerabilities.",
      },
      {
        path: "MobilePenetration.jpg",
        header: "Mobile Penetration Testing",
        caption:
          "With the wide and diversified utilisation of mobile phone, performing an in-depth analysis of mobile application (Android & IOS) to discover vulnerabilities in the application and also at the network level.",
      },
      {
        path: "DesktopAssessment.jpg",
        header: "Thick Client Penetration Testing",
        caption:
          "Have a legacy product in use? Or would like to perform an assessment for a software/Desktop application? We ensure to perform in depth analysis with reverse engineering.",
      },
    ],
  }),
};
</script>
<style scoped>
.icon-box:hover {
  background: #f7f9fd;
  border-radius: 10px;
  transform: translateY(-2px);
  cursor: pointer;
  border: 1px solid #4a90e2;
}
img.serviceimage {
  width: 100%;
  padding-bottom: 30px;
}
section#features {
  padding: 110px 30px 80px 30px;
  background: #f9f9f9;
}

.services .icon-box img {
  float: left;
}

.services .icon-box {
  margin-bottom: 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Softwareicon {
  padding: 50px 30px 10px 30px;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
}
.embservice {
  margin-left: 70px;
}
.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  padding: 20px;
}
@media (max-width: 998px) and (min-width: 0px) {
  section#features {
    padding: 40px;
  }
}
</style>

<template>
  <section class="services">
    <div class="container" data-aos="fade-up">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="services-title pt-4">
            <h1 class="pt-3">
              We provide State of the Art solutions in Cyber Security
            </h1>
            <p class="pt-3">
              We’re specialists in the field of cyber Security based in India.
              We provide security strategies to protect your organization from
              cyber-attacks. We evaluate the problem by anticipating the
              potential threats and risks involved in the current approach and
              deliver reliable solutions to secure your security infrastructure.
            </p>
            <form>
              <div class="form-group row pt-4">
                <div class="col-lg-6 pt-3">
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    v-model="email"
                    placeholder="Enter email"
                  />
                </div>
                <div class="col-lg-6 pt-3">
                  <button
                    type="button"
                    class="btn btn-primary emailbutton"
                    @click="doSubmit()"
                  >
                    Get Offer
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-6 col-12 pt-4 text-center"
          style="margin: auto"
        >
          <img
            src="@/assets/images/CyberBanner.png"
            alt="Img"
            style="width: 70%"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { doPost } from "@/utility/action.js";

export default {
  data() {
    return {
      email: null,
    };
  },
  methods: {
    doSubmit() {
      if (this.email) {
        doPost("get_offer", {
          type: window.location.href,
          email: this.email,
        });
      }
    },
  },
};
</script>
<style scoped>
.services {
  padding: 140px 40px 70px 40px;
}

@media (max-width: 998px) and (min-width: 0px) {
  .services {
    padding: 40px;
  }
}
input#exampleInputEmail1 {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
  border: none;
  padding: 10px;
  border-bottom: 2px solid;
}
.emailbutton {
  padding: 10px 32px;
  color: #0880e8;
  transition: 0.4s;
  border-radius: 50px;
  border: 2px solid #0880e8;
  background: #fff;
}
.emailbutton:hover {
  background: #0880e8;
  color: #fff;
}
</style>
